.rc-draggable-list-draggableRow {
    .move {
        opacity: 0;
        transition: all 0.5s;
        float: left;
    }

    &:hover .move {
        opacity: 1;
        transition: all 0.5s;
    }

    &:not(:hover) .move {
        opacity: 0;
        transition: all 0.5s;
    }

    &.active {
        border: 2px solid #00FF94;

        .move {
            opacity: 1;
            transition: all 0.5s;
        }

        &:after {
            border: 2px solid #00FF94;
        }

        &:before {
            display: block;
        }
    }
}